//Assets
import etapa1 from "../../assets/images/bolinha1.png"
import etapa2 from "../../assets/images/bolinha2.png"
import etapa3 from "../../assets/images/bolinha3.png"
import etapa4 from "../../assets/images/bolinha4.png"

// Logo Organizadores
// import pong from '../../assets/images/logos/organizadores/pong.png'
// import mantus from '../../assets/images/logos/organizadores/mantus.png'
// import gamejam from '../../assets/images/logos/organizadores/gamejam.png'
// import amagames from '../../assets/images/logos/organizadores/amagames.png'
// import izotonic from '../../assets/images/logos/organizadores/izotonic.png'
// import nerdproject from '../../assets/images/logos/organizadores/nerdproject.png'
// import indiesMx from '../../assets/images/logos/organizadores/indiesMx.png'
// import ring from '../../assets/images/logos/organizadores/ring.png'
// import abv from '../../assets/images/logos/organizadores/abv.png'
// import adevi from '../../assets/images/logos/organizadores/adevi.png'
import codeup from "../../assets/images/logos/organizadores/codeup.png"
import abv from "../../assets/images/logos/organizadores/21/abv.png"
import asodev from "../../assets/images/logos/organizadores/21/asodev.png"
import awg from "../../assets/images/logos/organizadores/21/awg.png"
import igda from "../../assets/images/logos/organizadores/21/igda.png"
import mgc from "../../assets/images/logos/organizadores/21/mgc.png"
import slovenia from "../../assets/images/logos/organizadores/21/slovenia.png"
import tgyj from "../../assets/images/logos/organizadores/21/tgyj.png"
import ipca from "../../assets/images/logos/organizadores/21/ipca.png"
import laval from "../../assets/images/logos/organizadores/21/laval.png"
import bonako from "../../assets/images/logos/organizadores/21/bonako.png"
import lab108 from "../../assets/images/logos/organizadores/21/lab108.png"
import pong from "../../assets/images/logos/organizadores/21/pong.png"
import techers from "../../assets/images/logos/organizadores/21/techers.jpg"
import gaming from "../../assets/images/logos/organizadores/21/gaming.png"
import bring from "../../assets/images/logos/organizadores/21/bring.png"
import mantus from "../../assets/images/logos/organizadores/21/mantus.png"
import amagames from "../../assets/images/logos/organizadores/21/amagames.png"
import gogo from "../../assets/images/logos/organizadores/21/gogo.png"
import nerdproject from "../../assets/images/logos/organizadores/21/nerdproject.jpg"
import teion from "../../assets/images/logos/organizadores/21/teion.png"
import devgirls from "../../assets/images/logos/organizadores/21/devgirls.png"
import adjogos from "../../assets/images/logos/organizadores/21/adjogos.png"
import playnambuco from "../../assets/images/logos/organizadores/21/playnambuco.png"
import ribamar from "../../assets/images/logos/organizadores/21/ribamar.png"
import sapiens from "../../assets/images/logos/organizadores/21/sapiens.png"
// import studio85 from '../../assets/images/logos/organizadores/21/studio85.png'
import tiradentes from "../../assets/images/logos/organizadores/21/tiradentes.png"
import unreal from "../../assets/images/logos/organizadores/21/unreal.png"
import dailyVR from "../../assets/images/logos/organizadores/21/dailyVR.jpeg"
import izotonic from "../../assets/images/logos/organizadores/21/izotonic.png"
import gamedevs from "../../assets/images/logos/organizadores/21/gamedevs.png"
import pinkbear from "../../assets/images/logos/organizadores/21/pinkbear.jpeg"
import odyssey from "../../assets/images/logos/organizadores/21/odyssey.jpeg"
import hubcg from "../../assets/images/logos/organizadores/21/hubcg.png"

// Logo Patrocinadores
// import anjoTintas from '../../assets/images/logos/patrocinadores/21/anjoTintas.png'
// import sebrae from '../../assets/images/logos/patrocinadores/21/sebrae.png'
// import indieHero from '../../assets/images/logos/patrocinadores/21/indieHero.png'
// import xphere from '../../assets/images/logos/patrocinadores/21/xphere.png'
// import hochmuller from '../../assets/images/logos/patrocinadores/21/hochmuller.png'
// import madmimic from '../../assets/images/logos/patrocinadores/21/madmimic.png'
// import gazeus from '../../assets/images/logos/patrocinadores/21/gazeus.png'
// import tiltingPoint from '../../assets/images/logos/patrocinadores/21/tiltingPoint.png'
// import poki from '../../assets/images/logos/patrocinadores/poki.png'
// import google from '../../assets/images/logos/patrocinadores/google.png'
// import akom from '../../assets/images/logos/patrocinadores/akom.png'
// import sebrae from '../../assets/images/logos/patrocinadores/sebrae.png'
// import unity from '../../assets/images/logos/patrocinadores/unity.png'
// import dangen from '../../assets/images/logos/patrocinadores/dangen.png'
// import uberstrategist from '../../assets/images/logos/patrocinadores/uberstrategist2.png'
// import ebg from '../../assets/images/logos/patrocinadores/ebg.png'
// import voodoo from '../../assets/images/logos/patrocinadores/voodoo.png'
// import igda from '../../assets/images/logos/patrocinadores/igda.png'
// import allCorrect from '../../assets/images/logos/patrocinadores/allCorrect.png'
// import gazeus from '../../assets/images/logos/patrocinadores/gazeus.png'
// import bigFestival from '../../assets/images/logos/patrocinadores/bigFestival.png'
// import bemobi from '../../assets/images/logos/patrocinadores/bemobi.png'
// import nuuvem from '../../assets/images/logos/patrocinadores/nuuvem.png'
// import hermitCrab from '../../assets/images/logos/patrocinadores/hermitCrab.png'
// import g4c from '../../assets/images/logos/patrocinadores/g4c.png'
// import tgibrasil from '../../assets/images/logos/patrocinadores/tgibrasil.png'
// import hackingrio from '../../assets/images/logos/patrocinadores/hackingrio.png'
// import flamengo from '../../assets/images/logos/patrocinadores/flamengo.png'
// import sapiens from '../../assets/images/logos/patrocinadores/sapiens.png'
// import gameplan from '../../assets/images/logos/patrocinadores/gameplan.png'
// import moonee from '../../assets/images/logos/publishers/moonee.svg'
// import consuladoUK from '../../assets/images/logos/patrocinadores/consuladoUK.png'
// import pathfindings from '../../assets/images/logos/patrocinadores/pathfindings.png'
import bemobi from "../../assets/images/logos/patrocinadores/22/bemobi.png"
import alura from "../../assets/images/logos/patrocinadores/22/alura.png"
import dt3 from "../../assets/images/logos/patrocinadores/22/dt3.png"
import artplan from "../../assets/images/logos/patrocinadores/22/artplan.png"
import vida from "../../assets/images/logos/patrocinadores/22/vida.png"
import apex from "../../assets/images/logos/patrocinadores/22/apex.png"
import gazeus from "../../assets/images/logos/patrocinadores/22/gazeus.png"
import sicoob from "../../assets/images/logos/patrocinadores/22/sicoob.png"
import anjo from "../../assets/images/logos/patrocinadores/22/anjo.png"
import tilting from "../../assets/images/logos/patrocinadores/22/tilting.png"
import madmimic from "../../assets/images/logos/patrocinadores/22/madmimic.png"
import prefeitura from "../../assets/images/logos/patrocinadores/22/prefeitura.png"

import firjan from "../../assets/images/logos/patrocinadores/22/firjan.png"
import sebrae from "../../assets/images/logos/patrocinadores/22/sebrae.png"
import xphere from "../../assets/images/logos/patrocinadores/22/xphere.png"
import indiehero from "../../assets/images/logos/patrocinadores/22/indiehero.png"
import visitrio from "../../assets/images/logos/patrocinadores/22/visitrio.png"
import rappi from "../../assets/images/logos/patrocinadores/22/rappi.png"
import plug from "../../assets/images/logos/patrocinadores/22/plug.png"
import usina from "../../assets/images/logos/patrocinadores/22/usina.png"
import dx from "../../assets/images/logos/patrocinadores/22/dx.png"

const Texts = {
  PT: {
    banner: "Copa do mundo de desenvolvimento de jogos",
    processo: "Mais do que criar um jogo",
    etapas: [
      {
        text: "Forme uma equipe e crie seu projeto de jogo em 54h com a ajuda de mentores experientes (online ou presencialmente) *consulte o organizador local",
        img: etapa1,
      },
      {
        text: "Os vencedores de cada cidade vão passar por aceleração online com mentoria de desenvolvedores ao redor do mundo",
        img: etapa2,
      },
      {
        text: "Os finalistas se encontram em 5 grandes eventos para disputar o primeiro lugar continental",
        img: etapa3,
      },
      {
        text: "Uma grande final internacional com as equipes finalistas revelará os vencedores escolhidos",
        img: etapa4,
      },
    ],
    mapa: "Cidades participantes",
    // video: videoPT,
    patrocinadores: "Patrocínio:",
    apoiadores: "Apoio:",
    organizadores: "Organizadores",
    countdown: "Término das inscrições dos participantes",
  },
  EN: {
    banner: "Game Development World Cup",
    processo: "More than creating a game",
    etapas: [
      {
        text: "Form a team and create your game project in 54 hours with the help of experienced mentors (online or in person) *consult your local organizer",
        img: etapa1,
      },
      {
        text: "The winners of each city will go through an online acceleration while being mentored by experienced developers around the world",
        img: etapa2,
      },
      {
        text: "Finalists meet in 5 major events to compete for continental first place",
        img: etapa3,
      },
      {
        text: "A great international final with the finalist teams will reveal the chosen winners",
        img: etapa4,
      },
    ],
    mapa: "Cities Confirmed",
    // video: videoEN,
    patrocinadores: "Sponsorship:",
    apoiadores: "Support:",
    organizadores: "Organizers",
    countdown: "End of participant registration",
  },
}

export const parceiros = [
  { img: abv, link: "https://www.abv.org.bo/" },
  { img: asodev, link: "https://www.asodev.cr/" },
  { img: awg, link: "https://www.amberwavesofgames.com/" },
  { img: slovenia, link: "https://sloveniagames.com/" },
  { img: tgyj, link: "https://tangrandeyjugando.com/" },
  { img: ipca, link: "https://ipca.pt/" },
  { img: codeup, link: "http://codeup.pt/" },
  { img: laval, link: "https://association.laval-virtual.com/" },
  { img: bonako, link: "https://www.bonakogames.com" },
  { img: lab108, link: "https://www.flowcode.com/page/labhashtag108" },
  { img: pong, link: "http://www.pongrn.com.br/" },
  { img: gaming, link: "https://agaming.com.br" },
  { img: mantus, link: "http://mantustudio.com/" },
  { img: hubcg, link: "" },
  { img: gogo, link: "" },
  { img: teion, link: "https://www.teiongames.com" },
  { img: izotonic, link: "www.izotonicstudios.com.br" },
  { img: gamedevs, link: "" },
  { img: devgirls, link: "https://ifrjdevgirl.wordpress.com/sobre/" },
  { img: adjogos, link: "https://adjogosrs.com.br/" },
  { img: sapiens, link: "https://faculdadesapiens.edu.br/" },
  { img: tiradentes, link: "https://www.studio85.games" },
  { img: ribamar, link: "https://www.saojosederibamar.ma.gov.br/" },
  // { img: studio85, link: "https://www.studio85.games" },
  { img: bring, link: "" },
  { img: amagames, link: "https://www.amagames.com.br/" },
  { img: playnambuco, link: "https://www.instagram.com/playnambuco/" },
  { img: igda, link: "https://www.facebook.com/IGDAGUILDBenilde" },
  { img: nerdproject, link: "" },
  { img: techers, link: "https://www.techers.com.br" },
  { img: dailyVR, link: "" },
  { img: pinkbear, link: "https://pinkbeargames.com/" },
  { img: odyssey, link: "" },
  { img: mgc, link: "" },
  { img: unreal, link: "https://unrealbahrain.com/" },
  // { img: amagames, link: "https://www.amagames.com.br/" },
  // { img: indiesMx, link: "https://www.indies.mx/" },
  // { img: ring, link: "https://ringdevrj.com.br/" },
  // { img: abv, link: "https://abv.org.bo/" },
  // { img: adevi, link: "https://www.facebook.com/groups/417504895294700/" }
]

export const patrocinadores = [
  { img: bemobi, link: "https://www.bemobi.com.br/" },
  { img: alura, link: "https://www.alura.com.br/" },
  { img: dt3, link: "https://www.dt3.com.br/" },
  { img: artplan, link: "http://www.artplan.com.br/" },
  { img: vida, link: "https://www.vidauti.com.br/" },
  { img: apex, link: "https://apexbrasil.com.br/" },
  { img: gazeus, link: "https://www.gazeus.com/" },
  { img: sicoob, link: "https://www.sicoob.com.br/" },
  { img: anjo, link: "https://www.anjo.com.br/" },
  { img: tilting, link: "https://www.tiltingpoint.com/" },
  { img: madmimic, link: "https://www.madmimic.com/" },
  { img: prefeitura, link: "https://prefeitura.rio/" },
  { img: sebrae, link: "https://www.sebrae.com.br/" },
]

export const apoiadores = [
  { img: firjan, link: "https://www.firjan.com.br/" },
  { img: xphere, link: "https://xphere.club/" },
  { img: indiehero, link: "https://indiehero.io/" },
  { img: visitrio, link: "https://rcvb.com.br/" },
  { img: rappi, link: "https://www.rappi.com.br/" },
  { img: plug, link: "http://plugandplus.com/" },
  { img: usina, link: "https://www.usinadestartup.com/" },
  { img: dx, link: "https://dxgameworks.com/" },
]

export default Texts
