import React from 'react'
import styled from 'styled-components'

const Banner = ({ img, link }) => (
  <BannerLink href={link} target="_blank">
    <Image src={img} alt='' />
  </BannerLink>
)

export default Banner

const BannerLink = styled.a`
  display: block;
  width: 100%;
`

const Image = styled.img`
  width: 100%;
`