import React from "react"
import styled from 'styled-components'

import Language from "../Language"

import patrocinio from '../../assets/images/patrocinio.png';
// import Monsters from '../../assets/images/monsters.png';

//Helpers
import Title from "../../helpers/Title"

//Texts
import texts from "./content"


const Patrocinador = () => (
  <PatrocinadorContainer>
    <img src={patrocinio} alt='' />
    <Title>{Language(texts).titulo}</Title>
    <Button href={Language(texts).linkBtn} target='_BLANK'  rel="noopener noreferrer">
          {Language(texts).botao}
     </Button>
     {/* <img src={Monsters} alt=''/> */}
  </PatrocinadorContainer>
)

export default Patrocinador

const PatrocinadorContainer = styled.section`
  width: 100%;
  /* max-width: 1240px; */
  margin: 0 auto;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #E6E6E6;

  img{
    padding-bottom: 20px;
    width: 180px;
  }

  #img-monster{
    height: 90%;
    position: absolute;
    right: 0;
    bottom: 5%;
    z-index: 1;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }
`

const Button = styled.a`
  display: flex;
  align-items: center;
  padding: .75rem;
  border: none;
  border-radius: .5rem;
  background: var(--pink);
  font-family: var(--bold);
  font-size: 1rem;
  color: var(--white);
  margin-bottom: 1.5rem;
`