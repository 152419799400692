import React from "react"
import styled from 'styled-components'

//Helpers
import Title from "../../helpers/Title"
import Card from "../../helpers/Card"


const Process = ({ title, content }) => (
  <ProcessContainer>
    <Title white>{title}</Title>
    <ProcessContent>
      {content.map(
        (item, index) => {
          return (
            <Card img={item.img} text={item.text} key={index}/>
          )
        }
      )}
    </ProcessContent>
  </ProcessContainer>
)

export default Process

const ProcessContainer = styled.section`
width: 100%;
padding: 4rem 1rem;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--darkBlue);
`

const ProcessContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  width: 100%;
  max-width: 1240px;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  } 
`