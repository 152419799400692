import React from "react"
import styled from 'styled-components'

//Components
import Language from "../Language"

//Imagens
import logo from '../../assets/images/logo.svg';
import headerBg from '../../assets/images/header.svg';
import headerMobileBg from '../../assets/images/headerMobile.svg';

//Textos
import texts from '../Home/content'


const Header = () => (
    <HeaderComp>
      <HeaderContainer>
        <HeaderContent>
          <HeaderBox>
            <Logo src={logo} alt='GameJam+ Logo' />
            <Divider />
            <Text>{Language(texts).banner}</Text>
          </HeaderBox>
        </HeaderContent>
      </HeaderContainer>
    </HeaderComp>
)

export default Header

const HeaderComp = styled.section`
  width: 100%; 
  min-width: 280px; 
  margin-top: -6vw;
  @media screen and (max-width: 768px) {
    margin-top: -4vw;
  }
`

const HeaderContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%; 
  height: 0;
  margin:0 auto;
`

const HeaderContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(${headerBg}) top center no-repeat;
  background-size: cover; 
  @media screen and (max-width: 768px) {
    background: url(${headerMobileBg}) top center no-repeat;
  }
`

const HeaderBox = styled.div`
  width: 20%;
  min-width: 180px;
  max-width: 350px;
  // height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 23%;
  left: 13%;
  z-index: 5;
  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: none;
    height: 70%;
    position: relative;
    flex-direction: row;
    top: 8vw;
    left: 0;
  }
`

const Logo = styled.img`
  width: 100%;
  position: relative;
  @media screen and (max-width: 768px) {
    width: 25%;
    height: auto;
  }
`

const Divider = styled.span`
  width: 85%;
  height: 5px;
  background: var(--gradient);
  margin-top: -26px;
  margin-bottom: 10%;
  @media screen and (max-width: 768px) {
    width: 3px;
    height: 55%;
    background: var(--vertGradient);
    margin-top: 0;
    margin-left: 16px;
    margin-bottom: 0;
  }
`

const Text = styled.h1`
  font-family: var(--primaryLight);
  font-size: 1.8vw;
  color: #a0a0a0;
  text-align: center;
  @media screen and (max-width: 768px) {
    width: 40%;
    font-size: 3.8vw;
  }
`