import React from 'react';
import styled from 'styled-components'

//Helpers
import Title from '../../helpers/Title';

//Components
import Language from "../Language"

//Assets
import fb from '../../assets/images/fb.png';
import insta from '../../assets/images/insta.png';
import contactBg from '../../assets/images/contact.png';

//Texts
import texts from "./content"

const Contact = () => (
  <ContactContainer>
      <ContactTitle white>{Language(texts).titulo}</ContactTitle>
      <ContactContent>
        <ContactBox>
          <Social href='https://www.facebook.com/gamejamplus/' target='_BLANK' rel="noopener noreferrer">
            <SocialIcon><Icon src={fb} alt='' /></SocialIcon>
            /gamejamplus
          </Social>
          <Social href='https://www.instagram.com/gamejamplus/' target='_BLANK' rel="noopener noreferrer">
            <SocialIcon><Icon src={insta} alt='' /></SocialIcon>
            @gamejamplus
          </Social>
        </ContactBox>
        <Form action="https://formspree.io/gamejamplus@gmail.com" method="POST">
          <Input type='text' name='nome' placeholder={Language(texts).nome} />
          <Input type='email' name='email' placeholder={Language(texts).email} />
          <Textarea name='mensagem' placeholder={Language(texts).msg} rows='5' />
          <Button type='submit' value={Language(texts).botao} />
        </Form>
      </ContactContent>
  </ContactContainer>
);

export default Contact

const ContactContainer = styled.section`
  background-color: var(--blue);
  background: url(${contactBg}) center;
  background-size: cover;
  padding: 4rem 0;

  @media screen and (max-width: 768px) {
    padding: 2rem 0;
  }
`

const ContactTitle = styled(Title)`
  margin-bottom: 3rem;

  @media screen and (max-width: 768px) {
    margin-bottom: 2rem;
  }
`

const ContactContent = styled.div`
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
  }
`

const ContactBox = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 2rem;
  }
`
const Social = styled.a`
  margin-bottom: 2rem;
  font-family: var(--regular);
  font-size: 1.5rem;
  color: var(--white);
  letter-spacing: .25rem;
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    margin: 0 1rem 1rem;
    font-size: 1rem;
  }
`
const SocialIcon = styled.span`
  margin-right: .5rem;
`
const Icon = styled.img`
  width: 2rem;

  @media screen and (max-width: 768px) {
    width: 1.5rem;
  }
`
const Form = styled.form`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 320px;
    margin-bottom: 2rem;
  }
`

const Input = styled.input`
  width: 100%;
  margin-bottom: 1.5rem;
  padding: .5rem;
  border: none;
  font-family: var(--regular);
  font-size: 1rem;
`

const Textarea = styled.textarea`
  width: 100%;
  margin-bottom: 1.5rem;
  padding: .5rem;
  border: none;
  font-family: var(--regular);
  font-size: 1rem;
  resize: none;
`

const Button = styled.input`
  background: var(--lightPink);
  padding: .5rem 1.5rem;
  border: none;
  font-size: 1rem;
  color: var(--white);
`