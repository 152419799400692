import React from 'react'
import styled from 'styled-components'

//Components
import Language from "../Language"

//Assets
import nextEdition from '../../assets/images/nextEdition.png';
import monsters from '../../assets/images/monsters.png';

//Texts
import texts from "./content"

const NextEdition = () => (
  <NextEditionContainer>
    <NextEditionContent>
      <Image src={nextEdition} alt='' />
      <NextEditionBox>
        <Title>{Language(texts).titulo}</Title>
        <Text>{Language(texts).volunteer}</Text>
        <Button href={Language(texts).btLinkVolunteer} target='_BLANK'  rel="noopener noreferrer">
          {Language(texts).btVolunteer}
        </Button>
        <Title>{Language(texts).organizador}</Title>
        <Text>{Language(texts).dtOrganizador}</Text>
        <Button href={Language(texts).btLinkOrganizador} target='_BLANK'  rel="noopener noreferrer">
          {Language(texts).btOrganizador}
        </Button>
        <Title>{Language(texts).final}</Title>
        <Text>{Language(texts).dtFinal}</Text>
      </NextEditionBox>
      <Monsters src={monsters} alt='' />
    </NextEditionContent>
</NextEditionContainer>
)

export default NextEdition

const NextEditionContainer = styled.section`
  display: flex;
  background: #e6e6e6;
`

const NextEditionContent = styled.div`
  width: 100%;
  height: auto;
  max-width: 1240px;
  margin: 0 auto;
  display: flex;
  position: relative;
  padding: 0;
`

const NextEditionBox = styled.div`
  margin-left: 2rem;
  padding: 2rem 0;
  color: var(--pink);
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (max-width: 768px) {
    margin: 0 auto;
    align-items: center;
  }
`
const Image = styled.img`
  @media screen and (max-width: 768px) {
    display: none;
  }
`

const Title = styled.h2`
  font-family: var(--primary);
  font-size: 2rem;
  margin-bottom: .5rem;

  @media screen and (max-width: 768px) {
    font-size: 1.25rem;
    text-align: center;
  }
`
const Text = styled.p`
  font-family: var(--primaryLight);
  font-size: 1.5rem;
  margin-bottom: .5rem;

  @media screen and (max-width: 768px) {
    font-size: 1rem;
    text-align: center;
  }
`
const Button = styled.a`
  display: block;
  padding: .75rem;
  border: none;
  border-radius: .5rem;
  background: var(--green);
  font-family: var(--bold);
  font-size: 1rem;
  color: var(--white);
  margin-bottom: 1.5rem;
`

const Monsters = styled.img`
  height: 90%;
  position: absolute;
  right: 0;
  bottom: 5%;
  z-index: 1;

  @media screen and (max-width: 768px) {
    display: none;
  }
`