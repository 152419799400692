import React from 'react';
import styled from 'styled-components'

const Card = ({ img, text }) => (
  <CardBox>
    <CardImage src={img} alt='' />
    <CardText>{text}</CardText>
  </CardBox>
)

export default Card

const CardBox = styled.div`
  width: 100%;
  max-width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;
`

const CardImage = styled.img`
  width: 80%;
  border: .25rem solid var(--pink);
  border-radius: 50%;
`

const CardText = styled.p`
  margin-top: 1rem;
  font-family: var(--bold);
  font-size: 1rem;
  font-weight: bolder;
  color: var(--blue);
  text-align: center;
  line-height: 1.5rem;
`