import React from "react"

//Helpers
import Video from "../helpers/Video"

//Components
import Layout from "../components/Layout"
import Language from "../components/Language"
import Seo from "../components/seo"
import Header from "../components/Header"
// import Countdown from "../components/Cowntdown"
import Process from "../components/Process"
import NextEdition from "../components/NextEdition"
import Map from "../components/Map"
import LogoSection from "../components/LogoSection"
import Contact from "../components/Contact"
import Patrocinador from "../components/Patrocinador"
import Banner from "../components/Banner"
// import Popup from "../components/Popup"

//Assets
import map from "../assets/images/edicoes/2020/map.jpg"
import bannerSebrae from "../assets/images/bannerSebrae.png"

//Textos
import texts, {
  patrocinadores,
  apoiadores,
  parceiros,
} from "../components/Home/content"
// import texts, { patrocinadores, parceiros } from '../components/Home/content'

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    {/* <Popup /> */}
    <Header />
    <Process
      title={Language(texts).processo}
      content={Language(texts).etapas}
    />
    {/* <Countdown
      data={`2021-10-08T18:00:00`}
      title={Language(texts).countdown}
    /> */}
    <Video id="fbA20IlVriU" />
    <NextEdition />
    <Map title={Language(texts).mapa} map={map} />
    <Patrocinador />
    <LogoSection title={Language(texts).patrocinadores} list={patrocinadores} />
    <LogoSection title={Language(texts).apoiadores} list={apoiadores} />
    <Banner
      img={bannerSebrae}
      link="https://www.sebrae.com.br/sites/PortalSebrae/brasilmais"
    />
    <LogoSection title={Language(texts).organizadores} list={parceiros} />

    <Contact />
  </Layout>
)

export default IndexPage
