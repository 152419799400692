const Texts = {
    PT: {
      titulo: 'Seja um patrocinador',
      botao: 'Quero ser um patrocinador',
      linkBtn: 'https://docs.google.com/presentation/d/11Rvv4aatHHn3-nAB-EHXwEnQsvxYrCqS3ujEL-ym0RQ/edit#slide=id.g3f2c9f24c1_0_487'
    },
    EN: {
      titulo: 'Be a sponsor',
      botao: 'I want to be a sponsor',
      linkBtn: 'https://docs.google.com/presentation/d/11Rvv4aatHHn3-nAB-EHXwEnQsvxYrCqS3ujEL-ym0RQ/edit#slide=id.g3f2c9f24c1_0_487'
    }
  }
  
  export default Texts