const Texts = {
  PT: {
    titulo: 'Próxima Edição GameJam+',
    volunteer: 'Seja um Voluntário',
    btVolunteer: 'Registre-se!',
    btLinkVolunteer: 'https://docs.google.com/forms/d/e/1FAIpQLSejOxC3YyfNyicTLujOV2lucLoIS4NU24kx3dIz-knyxDIkUw/viewform',
    organizador: 'Seja Organizador',
    dtOrganizador: 'Inscrição até dia 1 de Outubro',
    btOrganizador: 'Inscreva sua Cidade!',
    btLinkOrganizador: 'https://gamejamplus.com/seja-organizador/',
    regional: '1ª Etapa -  Etapa Regional',
    dtRegional: '09 a 11 de Outubro',
    btRegional: 'Faça sua Inscrição!',
    btLinkRegional: 'https://forms.gle/6QKd3e8sVhdXYeGv9',
    aceleracao: '2ª Etapa - Aceleração',
    dtAceleracao: 'Novembro e Dezembro',
    semifinal: '3ª Etapa - Semifinais Continentais',
    dtSemifinal: 'Janeiro/Fevereiro de 2021',
    final: 'Iniciar GameJam+ Edição 21/22',
    dtFinal: 'Outubro de 2021'
  },
  EN: {
    titulo: 'Next Game Jam+ Edition',
    volunteer: 'Become a volunteer',
    btVolunteer: 'Make your Registration!',
    btLinkVolunteer: 'https://docs.google.com/forms/d/e/1FAIpQLSejOxC3YyfNyicTLujOV2lucLoIS4NU24kx3dIz-knyxDIkUw/viewform',
    organizador: 'Be an organizer',
    dtOrganizador: 'Registration until October 1st',
    btOrganizador: 'Register your city!',
    btLinkOrganizador: 'https://gamejamplus.com/seja-organizador/en/',
    regional: '1st Stage - Regional Stage',
    dtRegional: 'October 09th to 11th',
    btRegional: 'Make your Registration!',
    btLinkRegional: 'https://forms.gle/Ey7f66CsZZwu1ZgA6',
    aceleracao: '2nd Stage - Acceleration',
    dtAceleracao: 'November and December',
    semifinal: '3rd Stage - Continental Semifinals',
    dtSemifinal: 'January/February 2021',
    final: 'Start GameJam+ 21/22 Edition',
    dtFinal: 'October 2021'
  }
}

export default Texts