import React from 'react'
import styled from 'styled-components'

//Helpers
import Title from '../../helpers/Title';

const Map = ({ title, map }) => (
  <MapContainer>
    {title ? <MapTitle>{title}</MapTitle> : null}
    <iframe title="MapCitys" src="https://www.google.com/maps/d/u/1/embed?mid=1xkUh8jGF4mpjbBvMZJxHrE5vV5qNkPix" width="100%" height="480" display="none"></iframe>
  </MapContainer>
)

export default Map

const MapContainer = styled.section`
  width: 100%;
  max-width: 1240px;
  margin: 4rem auto;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  iframe[src*=".i4ewOd-pzNkMb-haAclf"] {
    display: none;
  }
`

const MapTitle = styled(Title)`
  margin-bottom: 20px;
`

// const MapImage = styled.img`
//   width: 80%;

//   @media screen and (max-width: 768px) {
//     width: 100%;
//   }
// `
