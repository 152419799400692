const Texts = {
  PT: {
    titulo: 'Fale com a gente',
    nome: 'Nome',
    email: 'E-mail',
    msg: 'Mensagem',
    botao: 'Enviar'
  },
  EN: {
    titulo: 'Contact us',
    nome: 'Name',
    email: 'Email',
    msg: 'Message',
    botao: 'Send'
  }
}

export default Texts